<template>
  <div id="pending_accounts">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.pendingAccs') }}</div>
        <div class="info_box p-3 p-md-5">
          <p>{{ $t('pendingAccounts.errorDoc') }}</p>
          <p>{{ $t('pendingAccounts.acceptedDocType') }}</p>
          <div class="flex flex-between">
            <div class="primary">
              <h3>{{ $t('pendingAccounts.primaryDoc') }}</h3>
              <ul>
                <li><em class="icon el-icon-check text-cyan"></em>{{ $t('pendingAccounts.passport') }}</li>
                <li><em class="icon el-icon-check text-cyan"></em>{{ $t('pendingAccounts.id') }}</li>
                <li><em class="icon el-icon-check text-cyan"></em>{{ $t('pendingAccounts.dl') }}</li>
                <li><em class="icon el-icon-check text-cyan"></em>{{ $t('pendingAccounts.divc') }}</li>
                <li><em class="icon el-icon-check text-cyan"></em>{{ $t('pendingAccounts.dric') }}</li>
              </ul>
            </div>
            <div class="secondary">
              <h3>{{ $t('pendingAccounts.address') }}</h3>
              <ul>
                <li v-for="(list, index) in $t('pendingAccounts.addressList')" :key="index">
                  <em class="icon el-icon-check text-cyan"></em>{{ list }}
                </li>
              </ul>
            </div>
          </div>
          <p>
            {{ $t('pendingAccounts.tip') }}
            <a :href="'mailto:' + GLOBAL_ACCOUNT_EMAIL">{{ GLOBAL_ACCOUNT_EMAIL }}</a>
          </p>
        </div>
        <div class="table">
          <div class="table_box">
            <div class="item_header">{{ $t('menu.pendingAccs') }}</div>
            <el-table :data="displayData" style="width: 100%">
              <el-table-column :label="$t('common.keys.DATE')" min-width="100">
                <template slot-scope="scope">{{ scope.row.register_date | date }}</template>
              </el-table-column>
              <el-table-column prop="applyName" :label="$t('common.keys.NAME')"></el-table-column>
              <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="100"></el-table-column>
              <el-table-column :label="$t('common.keys.ACCTYPE')" min-width="100">
                <template slot-scope="scope">
                  {{ $platform.accountTypeMaps(regulator)[scope.row.account_type] }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mt4_datasource"
                :label="$t('common.keys.PLATFORM')"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="apply_currency"
                :label="$t('common.keys.BASECURRENCY')"
                min-width="100"
              ></el-table-column>
            </el-table>
          </div>
          <Pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetPendingAccount } from '@/resource';
import Pagination from '@/components/Pagination';

export default {
  components: { Pagination },
  data() {
    return {
      // 分页
      userID: this.$store.state.common.CUID,
      total: 0,
      displayData: [],
      tableData: []
    };
  },
  mounted() {
    this.queryPendingUser();
  },
  methods: {
    queryPendingUser() {
      apiGetPendingAccount({
        pid: this.userID
      }).then(response => {
        this.tableData = response.data;
        this.total = this.tableData.length;
      }, this.$handleError);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/pendingAccounts.scss';
</style>
